import styled from "styled-components";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import {MasterData, MasterDiverContentData} from "./data";
import DiverInfo from "../openwater/diver-info";

const Wrap = styled.div`
`
const Master = () => {
    return(
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={MasterData}/>
            <DiverInfo info={MasterDiverContentData}/>
        </Wrap>
    )
}

export default Master;