import {InfoListTableType} from "../../types/common";

export const PriceContentData: InfoListTableType[] = [
    {
        title: '',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '펀다이빙 숙박 패키지',
                    },
                    {
                        price: '$150',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '다이빙',
                                    description: '(2~3회)',
                                },
                                {
                                    title: '1일 숙박',
                                    description: '(2인 1실)',
                                },
                                {
                                    title: '공항픽업',
                                },
                                {
                                    title: '조식/중식',
                                },
                            ],
                        },
                        descriptionList: [
                            '* 해상공원 입장료 및 씨푸드 레스토랑 별도',
                            '* 숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.'
                        ]
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '1일 펀다이빙',
                    },
                    {
                        price: '$120',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '다이빙',
                                    description: '(2~3회)',
                                },
                                {
                                    title: '중식',
                                },
                            ],
                        },
                        descriptionList: [
                            '* 해상공원 입장료 및 씨푸드 레스토랑 별도',
                            '* 숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.'
                        ]
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '펀다이빙 1회(보트 다이빙)',
                    },
                    {
                        price: '$50',
                        description: '해상공원 입장료 및 씨푸드 레스토랑 별도',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '펀다이빙 1회(비치 다이빙)',
                    },
                    {
                        price: '$40',
                        description: '해상공원 입장료 및 씨푸드 레스토랑 별도',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '나이트록스',
                    },
                    {
                        description: '1회 다이빙시',
                        addPrice: '$15 추가'
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '야간 다이빙 1회(보트 다이빙)',
                    },
                    {
                        price: '$50'
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '야간 다이빙 1회(비치 다이빙)',
                    },
                    {
                        price: '$50'
                    }
                ]
            }
        ],
    },
]

export const PriceFoodData: InfoListTableType[] = [
    {
        title: '',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {description: '아침식사',},
                    {price: '$8'}
                ]
            },
            {
                contents: [
                    {description: '점심식사',},
                    {price: '$10'}
                ]
            },
            {
                contents: [
                    {description: '저녁식사',},
                    {price: '$13'}
                ]
            },
            {
                contents: [
                    {description: '삼겹살 1인기준',},
                    {price: '$15'}
                ]
            },
            {
                contents: [
                    {description: '레촌',},
                    {price: '싯가'}
                ]
            },
            {
                contents: [
                    {description: '객실 1박(조식포함)',},
                    {price: '$120'}
                ]
            },
        ],
    },
]

export const PriceRentalData: InfoListTableType[] = [
    {
        title: '렌탈 요금표',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '풀셋 1일',
                    },
                    {
                        description: '',
                        price: '$30',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '풀세트',
                                    description: '(랜턴제외)'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                contents: [
                    { description: 'BCD', },
                    { price: '$15' }
                ]
            },
            {
                contents: [
                    { description: '호흡기세트(주/보조호흡기/게이지)', },
                    { price: '$15' }
                ]
            },
            {
                contents: [
                    { description: 'tbxm', },
                    { price: '$10' }
                ]
            },
            {
                contents: [
                    { description: '핀', },
                    { price: '$5' }
                ]
            },
            {
                contents: [
                    { description: '마스크', },
                    { price: '$5' }
                ]
            },
        ]
    },
    {
        title: '해상공원 입장료 및 씨푸드 레스토랑',
        subTitle: ['해상공원 및 금액', '비고'],
        list: [
            {
                contents: [
                    {
                        description: '올랑고',
                        addPrice: '$2',
                    },
                    {
                        description: '씨푸드 레스토랑 이용시 추가 금액',
                        addPrice: '$12'
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '힐룽뚱안',
                        addPrice: '$6',
                    },
                    {
                        description: '-',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '날루수안',
                        addPrice: '$6',
                    },
                    {
                        description: '-',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '샹그릴라',
                        addPrice: '$6',
                    },
                    {
                        description: '-',
                    }
                ]
            },
        ]
    }
]