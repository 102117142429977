import {InfoListTableType} from "../../../types/common";

export const ResortImagesData: string[] = [
    '/image/img_package_item_00.png',
    '/image/img_package_item_00.png',
    '/image/img_package_item_00.png',
    '/image/img_package_item_00.png',
]

export const ResortData: InfoListTableType[] = [
    {
        title: '숙소 안내',
        subTitle: ['객실 타입', '기준 인원', '객실 구성'],
        list: [
            {
                contents: [
                    { description: '디럭스', },
                    { description: '2', },
                    {
                        description: '방 1, 퀸사이즈침대 2, 화장실 1',
                    },
                ]
            },
            {
                contents: [
                    { description: '다인실', },
                    { description: '4', },
                    {
                        description: '방 1, 2층침대 2, 화장실 1',
                    },
                ]
            },
        ],
    }
]

export const ResortEquipmentData: InfoListTableType[] = [
    {
        title: '',
        subTitle: ['공동비품'],
        list: [
            {
                contents: [
                    {
                        description: '' +
                            '에어컨, 책상, 냉장고, 금고, 헤어드라이어, 수건\n' +
                            '모든 객실 온수 사용 가능\n' +
                            '모든 객실 WIFI 사용 가능\n' +
                            '객실 체크인 시간은 오후 2시, 체크아웃 시간은 오전 12시가 기준입니다.\n',
                    },
                ]
            },
        ],
    }
]
