import styled from "styled-components";
import media from "../../../lib/media";


export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0;
    font-size: 0;
    ${ media.tablet`
        padding: 60px 0 0;
    ` }
`

export const SteffCEO = styled.div`
    font-size: 0;
`

export const ImgBx = styled.div`
    margin-bottom: 5px;
    img{
        width: 100%;
    }
`

export const ImgList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    img{
        width: 100%;
    }
`

export const Career = styled.div`
`

export const CareerListBx = styled.div`
    display: flex;
    justify-content: center;
    ${ media.mobile`
        flex-direction: column;
    ` }
`

export const CareerList = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 40px;
    box-sizing: border-box;
    padding: 0 20px;

    ul{
        display: flex;
        flex-direction: column;
        gap: 10px;
        &:last-child{
            border-right: 0;
        }
        li{
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            word-break: keep-all;
        }
    }
    
    &:first-child{
        border-right: 1px solid #cccccc;
        ul{
            li{
                text-align: right;
            }
        }
    }
    
    ${ media.mobile`
        width: 100%;
        padding: 0px;
        gap: 30px;
        
        ul{
            li{
                font-size: 15px;
                line-height: 16px;
                text-align: center;
            }
        }
        
        &:first-child{
            margin-bottom: 30px;
            border-right: none;
            ul{
                li{
                    text-align: center;
                }
            }
        }
    ` };
`

export const SteffBx = styled.div`
    margin-top: 150px;
    box-sizing: border-box;
    ${ media.tablet`
        padding: 0 20px;
    ` }
    
    ${ media.mobile`
        margin-top: 70px;
    ` };
`

export const SteffListBx = styled.div`
    display: flex;
    flex-wrap: wrap;
    
    ${ media.tablet`
        flex-direction: column;
        flex-wrap: nowrap;
    ` };
`

export const SteffList = styled.div`
    width: 50%;
    display: flex;
    box-sizing: border-box;
    padding: 0 20px 20px;
    
    &:nth-child(2n+1){
        border-right: 1px solid #cccccc;
        flex-direction: row-reverse;
        dl{
            text-align: right;
            dt{
            }
            dd{
                
            }
        }
    }
    
    img{
        width: 300px;
        height: 300px;
        object-fit: cover;
        object-position: center;
        border-radius: 20px;
    }
    
    dl{
        text-align: left;
        padding: 20px;
        dt{
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 20px;
            color: #0F0F10;
        }
        dd{
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            word-break: keep-all;
            margin-bottom: 10px;
            color: #0F0F10;
        }
    }
    
    ${ media.tablet`
        width: 100%;
        justify-content: center;
        &:nth-child(2n+1){
            border-right: none;
            flex-direction: row;
            dl{
                text-align: left;
            }
        }
        
        dl{
            width: 300px;
        }
    ` };
    
    ${ media.mobile`
        padding: 0 0 20px;
        img{
            width: 150px;
            height: 150px;
        }
        
        dl{
            width: 100%;
            padding-top: 10px;
            dt{
                font-size: 18px;
            }
            dd{
                font-size: 15px;
                margin-bottom: 6px;
            }
        }
    ` };
`

export const TeamBx = styled.div`
    margin-top: 150px;
    img{
        width: 100%;
        border-radius: 20px;
    }
    
    ${ media.tablet`
        img{
            border-radius: 0px;
        }
    ` }
    
    ${ media.mobile`
        margin-top: 70px;
    ` }
`