import Resort from "../../../components/team-max/resort";

const ResortPage = () => {
    return(
        <div>
            <Resort></Resort>
        </div>
    )
}

export default ResortPage;