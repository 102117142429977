import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    min-height: 100dvh;
`

export const Inner = styled.div`
    padding-top: 75px;
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
`

export const TitleBx = styled.dl`
    text-align: left;
    margin-bottom: 110px;
    dt{
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    dd{
        font-size: 20px;
        font-weight: 600;
    }
`

export const ListContaienr = styled.div`
    width: 100%;
`

export const ListBx = styled.div`
    ul{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        li{
            a{
                position: relative;
                display: block;
                border: 1px solid #dddddd;
                border-radius: 15px;
                padding: 20px;
                box-sizing: border-box;
                transition: background-color 0.25s, transform 0.25s, border 0.25s, box-shadow 0.25s;
                &:hover{
                    transform: translateY(-5px);
                    border: 1px solid #999999;
                    box-shadow: 3px 3px 4px rgba( 0, 0, 0, 0.25 );
                }
            }
        }
    }
`

export const Process = styled.div`
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #111111;
`

export const MessageBx = styled.div`
    font-size: 14px;
    line-height: 23px;
    font-weight: 600;
    height: 95px;
    overflow: hidden;
    margin-bottom: 30px;
    color: #666666;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:4;
    white-space: pre;
`

export const InfoBx = styled.dl`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 7px;
    
    dt{
        font-size: 14px;
        font-weight: 500;
        color: #666666;
    }
    dd{
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        color: #666666;
        &:before{
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            background-color: #999999;
            border-radius: 50%;
        }
    }
`
