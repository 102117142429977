import * as S from "./styles";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import Gmap from "./g-map";
import {useRef} from "react";
import SubTitle from "../../common/sub-title";
import TableComponent from "../../table-component";
import {ContactData} from "./data";

const Contact = () => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const render = (status: Status) => {

        if( mapContainerRef.current ){
            switch (status) {
                case Status.LOADING:
                case Status.FAILURE:
                case Status.SUCCESS:
                    return <Gmap container={mapContainerRef.current} />;
            }
        }else{
            return <></>
        }
    };

    return(
        <S.Wrap>
            <S.Inner>
                <SubTitle txt={'오시는 길'}></SubTitle>
                <S.InfoBx>
                    <ul>
                        <li>1. 입국 심사장을 통과 하세요.</li>
                        <li>2. 가방을 찾으시고, 세관 통과를 하세요.</li>
                        <li>3. 입구쪽으로 오시면, 우측에 환전소가 보입니다.</li>
                        <li>4. 환전소를 지나면 입구 정면이 보입니다.</li>
                        <li>5. 입구를 통과하시면 큼직하게 TEAM MAX DIVE 깃발을 들고 픽업 직원이 있습니다.</li>
                    </ul>
                    
                    <S.InfoImageBx>
                        {/*<span>이래도 못찾을꺼야? 정말?</span>*/}
                        <img src="/image/img_contact_man.jpg" alt=""/>
                    </S.InfoImageBx>
                </S.InfoBx>

                <S.MapBx>
                    <Wrapper
                        apiKey="AIzaSyDTBk9ygjxvVMy-a99bauzRV_bXGY3sFzI"
                        render={render}
                        libraries={['marker']}
                    />

                    <div id='map' style={{ height: '50dvh' }} ref={mapContainerRef}></div>
                </S.MapBx>

                <TableComponent info={ContactData}></TableComponent>
                <S.ImgBx>
                    <img src="/image/img_contact.jpg" alt=""/>
                </S.ImgBx>

            </S.Inner>
        </S.Wrap>
    )
}

export default Contact;