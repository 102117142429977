import styled from "styled-components";
import Visual from "./visual";
import TeamAbout from "./team-about";
import DivePackage from "./dive-package";
import Notice from "./notice";
import TeamMaxStore from "./team-max-store";

const Wrap = styled.div`
`

const MainContent = () => {
    return(
        <Wrap>
            <Visual
                title='TEAM MAX DIVE RESORT'
                imgSrc={"/image/img_main_visual.png"}
            />
            <TeamAbout />
            <DivePackage />
            <Notice />
            <TeamMaxStore />
        </Wrap>
    )
}

export default MainContent;