import styled from "styled-components";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import {RescueData, RescueDiverContentData, RescuedPackageData} from "./data";
import DiverInfo from "../openwater/diver-info";
import AdvencedPackage from "../../common/advanced-package";

const Wrap = styled.div``

const Rescue = () => {
    return(
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={RescueData}/>
            <DiverInfo info={RescueDiverContentData}/>
            <AdvencedPackage info={RescuedPackageData}/>
        </Wrap>
    )
}

export default Rescue;