import {InfoListTableType} from "../../../types/common";

export const ContactData: InfoListTableType[] = [
    {
        title: '팀맥스 연락처',
        subTitle: [],
        list: [
            {
                contents: [
                    { description: '주소', },
                    { description: '$6015 TEAM MAX DIVE SHOP BAYBYON BUYONG MARIBAGO LAPU_LAPU CITY', },
                ]
            },
            {
                contents: [
                    { description: '연락처', },
                    { description: '(인터넷전화) 070-8800-8070 / 070-7723-8070\n(현지전화)+63-917-934-8234', },
                ]
            },
            {
                contents: [
                    { description: '이메일', },
                    { description: 'lwk9120@naver.com', },
                ]
            },
        ],
    }
]
