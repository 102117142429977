import ImgSwiper from "../../img-swiper";
import {ResortImagesData, ResortData, ResortEquipmentData} from "./data";
import styled from "styled-components";
import TitleBx from "../../common/title-bx";
import TableComponent from "../../table-component";
import media from "../../../lib/media";

const Wrap = styled.div``;
const Inner = styled.div`
    padding: 100px 0 0;
    ${ media.mobile`
        padding: 60px 0 0;
    ` }
`

const Resort = () => {
    return(
        <Wrap>
            <Inner>
                <TitleBx
                    title={'RAZOR SIDE MOUNT COURSE'}
                    description={'즐거운 바다로, 안전하고 편안하게 안내해 드리겠습니다.'}
                />

                <ImgSwiper info={ResortImagesData} />
                <TableComponent info={ResortData}></TableComponent>
                <TableComponent info={ResortEquipmentData}></TableComponent>
            </Inner>
        </Wrap>
    )
}

export default Resort;