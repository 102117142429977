import {HeaderType} from "../../../types/header";

export const HeaderData: HeaderType[] = [
    {
        title: '팀맥스',
        link: '/team-max',
        sub: [
            {
                title: '팀맥스 소개',
                link: '/team-max/about',
            },
            {
                title: '강사소개',
                link: '/team-max/steff',
            },
            {
                title: '숙소소개',
                link: '/team-max/resort',
            },
            {
                title: '오시는길',
                link: '/team-max/contact',
            },
        ]
    },
    {
        title: '펀 다이빙',
        link: '/expense',
        sub: [
            {
                title: '비용안내',
                link: '/expense/price',
            },
            // {
            //     title: '비용 계산기',
            //     link: '/expense/calculator',
            // },
            // {
            //     title: '비용 작성 리스트',
            //     link: '/expense/list'
            // }
        ]
    },
    {
        title: '레크레이션 다이빙',
        link: '/recreation',
        sub: [
            {
                title: '오픈워터 다이버',
                link: '/recreation/open-water',
            },
            {
                title: '어드벤스드 다이버',
                link: '/recreation/advanced',
            },
            {
                title: '나이트록스 다이버',
                link: '/recreation/nitrox',
            },
            {
                title: '레스큐 다이버',
                link: '/recreation/rescue',
            },
            {
                title: '응급처치',
                link: '/recreation/cpr',
            },
            {
                title: '마스터 스쿠버 다이버',
                link: '/recreation/master',
            },
        ]
    },
    {
        title: '테크니컬 교육',
        link: '/technical',
    },
    {
        title: 'RAZOR코스',
        link: '/razor',
    },
];