import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Header from "./components/common/header";
import Footer from "./components/common/footer";

import MainPage from "./pages/MainPage";

import AboutPage from "./pages/team-max/AboutPage";
import ResortPage from "./pages/team-max/ResortPage";
import ContactPage from "./pages/team-max/ContactPage";
import SteffPage from "./pages/team-max/SteffPage";

import OpenWaterPage from "./pages/recreation/OpenWaterPage";
import AdvancedPage from "./pages/recreation/AdvancedPage";
import NitroxPage from "./pages/recreation/NitroxPage";
import RescuePage from "./pages/recreation/RescuePage";
import CprPage from "./pages/recreation/CprPage";
import MasterPage from "./pages/recreation/MasterPage";

import TechnicalPage from "./pages/TechnicalPage";
import RazorPage from "./pages/RazorPage";
import PricePage from "./pages/expense/PricePage";

import ToastProvider from "./components/popup-table-component/ToastProvider";
import CalculatorPage from "./pages/expense/CalculatorPage";
import CompletePage from "./pages/expense/CompletePage";
import CalculatorListPage from "./pages/expense/CalculatorListPage";

function App() {

    return (
        <div className='App'>
            <BrowserRouter>
                <ToastProvider>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<MainPage/>}></Route>

                        <Route path="/team-max">
                            <Route path="about" element={<AboutPage/>}></Route>
                            <Route path="steff" element={<SteffPage/>}></Route>
                            <Route path="resort" element={<ResortPage/>}></Route>
                            <Route path="contact" element={<ContactPage/>}></Route>
                        </Route>

                        <Route path="/recreation">
                            <Route path="open-water" element={<OpenWaterPage/>}></Route>
                            <Route path="advanced" element={<AdvancedPage/>}></Route>
                            <Route path="nitrox" element={<NitroxPage/>}></Route>
                            <Route path="rescue" element={<RescuePage/>}></Route>
                            <Route path="cpr" element={<CprPage/>}></Route>
                            <Route path="master" element={<MasterPage/>}></Route>
                        </Route>

                        <Route path="technical" element={<TechnicalPage/>}></Route>
                        <Route path="razor" element={<RazorPage/>}></Route>

                        <Route path="expense">
                            <Route path="price" element={<PricePage/>}></Route>
                            <Route path="calculator" element={<CalculatorPage />}></Route>
                            <Route path="complete/:num" element={<CompletePage />}></Route>
                            <Route path="list" element={<CalculatorListPage />}></Route>
                        </Route>

                    </Routes>
                    <Footer/>
                </ToastProvider>
            </BrowserRouter>
        </div>
    )
}

export default App;
