import styled from "styled-components";
import media from "../../lib/media";
import {motion} from "framer-motion";

const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    line-height: 0;
    position: relative;
    overflow: hidden;

    dl {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 160px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        dt {
            font-family: Poppins sans-serif;
            font-weight: 700;
            font-size: 48px;
            line-height: 57px;
            color: #ffffff;
            word-break: keep-all;
            text-align: center;
        }

        dd {
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
            opacity: 0.5;
        }
    }

    ${media.tablet`
        dl{
            padding: 0 20px;
            box-sizing: border-box;
            top: 100px;
            dt{
                max-width: 580px;
                width: 100%;
            }
        }
    `};

    ${media.mobile`
        height: 420px;
        overflow: hidden;
        dl{
            top: 48px;
            gap: 12px;
            dt{
                font-size: 20px;
                line-height: 30px;
            }
            
            dd{
                font-size: 14px;
                line-height: 19px;
            }
        }
    `};
`

const ImgBx = styled(motion.div)`
    img {
        width: 100%;
    }

    ${media.mobile`
        img{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 834px;
            height: 420px;
        }
    `};
`

interface Props {
    title: string;
    description?: string;
    imgSrc: string;
}

const Visual = ({title, description, imgSrc}: Props) => {
    return (
        <Wrap>
            <motion.dl
                initial={{y: 20, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{
                    duration: 0.5,
                    ease: 'easeOut',
                }}
            >
                <dt>{title}</dt>
                {
                    description && <dd>{description}</dd>
                }
            </motion.dl>
            <ImgBx
                initial={{scale: 1}}
                animate={{scale: 1.5}}
                transition={{
                    duration: 60,
                    ease: 'linear',
                    repeat: Infinity,
                    repeatType: 'reverse',
                }}
            >
                <motion.img
                    src={imgSrc} alt=""
                />

            </ImgBx>
        </Wrap>
    )
}

export default Visual;