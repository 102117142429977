import styled from "styled-components";
import media from "../../../../lib/media";

export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`;

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0 180px;
    ${ media.tablet`
        padding: 100px 0px 80px;
        box-sizing: border-box;
    ` };
    
    ${ media.mobile`
        padding: 40px 0px;
    ` };
`

export const VideoBx = styled.div`
    max-width: 1300px;
    width: 100%;
    aspect-ratio: 1 / calc(731/1300);
    box-sizing: border-box;
    iframe{
        width: 100%;
        height: 100%;
    }
    
    ${ media.tablet`
        padding: 0 20px;
    ` }
`