import styled from "styled-components";
import media from "../../../../lib/media";

export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    background-color: #0F0F10;
    margin: 0 auto;
`

export const Inner = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 120px 0;
    ${ media.tablet`
        padding: 120px 20px;
        box-sizing: border-box;
    ` };
    
    ${ media.mobile`
        padding: 40px 20px;
    ` };
`

export const Title = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #ffffff;
    
    ${ media.mobile`
        font-size: 20px;
        line-height: 28px;
    ` };
`

export const ItemBx = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    
    ${ media.mobile`
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
    ` };
`

export const ItemWrap = styled.div`
    background-color: #C6151B;
    border-radius: 32px;
    overflow: hidden;
    box-sizing: border-box;
`

export const ItemInner = styled.div`
    padding: 32px;
    position: relative;
    width: 100%;
    height: 210px;
    box-sizing: border-box;
    dl{
        display: flex;
        flex-direction: column;
        gap: 8px;
        dt{
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            color: #ffffff;
        }
        dd{
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
        }
    }
    
    ${ media.tablet`
        height: 250px;
    ` };

    ${ media.mobile`
        padding: 20px;
        height: auto;
        dl{
            dt{
                font-size: 16px;
                line-height: 19px;
            }
            dd{
                font-size: 14px;
                line-height: 19px;
            }
        }
    ` };
`

export const ImgBx = styled.div`
    position: absolute;
    bottom: 20px;
    right: 24px;
    
    img{
        width: 100%;
    }
    
    ${ media.mobile`
        position: relative;
        bottom: 0;
        right: 0;
        width: 100%;
        text-align: right;
        margin-top: 16px;
        img{
            width: 106px;
        }
    ` };
`