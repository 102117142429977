import {Link} from "react-router-dom";
import * as S from "./styles";

const Footer = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <S.MenuBx>
                    <h3>TEAM MAX DIVE RESORT</h3>
                    <ul>
                        <li><Link to={"/team-max/about"}>팀맥스 소개</Link></li>
                        <li><Link to={"/team-max/contact"}>찾아오시는 길</Link></li>
                        <li><Link to={"/price"}>가격안내</Link></li>
                        <li><Link to={"/"}>공지사항</Link></li>
                    </ul>
                </S.MenuBx>
                <S.InfoBx>
                    <S.FooterInfo>
                        <dl>
                            <dt>대표 :</dt>
                            <dd>이우권</dd>
                        </dl>
                        <dl>
                            <dt>주소 :</dt>
                            <dd>#6015 TEAM MAX DIVE SHOP BAYBYON BUYONG MARIBAGO LAPU-LAPU CITY</dd>
                        </dl>
                        <dl>
                            <dt>E-mail :</dt>
                            <dd>
                                <a href="mailto:lwk9120@naver.com">lwk9120@naver.com</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>인터넷전화(시내요금 적용)</dt>
                            <dd>070 8800 8070 / 070 7723 8070</dd>
                        </dl>
                    </S.FooterInfo>
                    <S.Copyright>Copyright © TEAM MAX DIVE RESORT. All Rights Reserved.</S.Copyright>
                </S.InfoBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Footer;