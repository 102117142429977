import styled from "styled-components";
import TeamAboutItem from "./team-about-item";
import {ItemData} from "./data";
import media from "../../../lib/media";
import {motion} from "framer-motion";
import {useInStaggerAction} from "../../../hooks/useInStaggerAction";

const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    background-color: #0F0F10;
    margin: 0 auto;
`

const Inner = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 120px 0;

    ${media.tablet`
        padding: 120px 20px;
        box-sizing: border-box;
    `};

    ${media.mobile`
        padding: 40px 20px;
    `};
`

const Title = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #ffffff;

    ${media.mobile`
        font-size: 20px;
        line-height: 28px;
    `};
`

const ItemBx = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
    `};

    ${media.mobile`
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
    `};
`

const TeamAbout = () => {
    const {
        controls,
        ref,
        variants,
        itemVariants
    } = useInStaggerAction({});

    return (
        <Wrap ref={ref}>
            <Inner>
                <Title>팀맥스에서는요 ...</Title>
                <ItemBx
                    initial="hidden"
                    animate={controls}
                    variants={variants}
                >
                    {
                        ItemData.map((item, idx) => (
                            <motion.div
                                key={`about-item-${idx}`}
                                variants={itemVariants}
                            >
                                <TeamAboutItem
                                    icon={item.icon}
                                    title={item.title}
                                    description={item.description}
                                />
                            </motion.div>
                        ))
                    }
                </ItemBx>
            </Inner>
        </Wrap>
    )
}

export default TeamAbout;