
import styled from "styled-components";
import media from "../../lib/media";

export const Wrap = styled.div`
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 75px 0;
    box-sizing: border-box;
    
    ${ media.tablet`
        padding: 60px 20px;
    ` };
`


export const TitleBx = styled.div`
    padding-top: 75px;
    
    h3{
        font-size: 36px;
        font-weight: 700;
    }
    
    ${ media.mobile`
        flex-direction: column;
        gap: 10px;
        margin-bottom: 50px;
    ` };
`

export const ShareBx = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    
    flex-direction: row-reverse;
    box-sizing: border-box;
    ${ media.tablet`
        padding: 0 20px;
    ` };
    
    ${ media.mobile`
        flex-direction: row;
    ` };
`

export const ContentBx = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 70px;
`

export const TextAreaBx = styled.textarea`
    width: 10px;
    height: 10px;
    margin-bottom: 10px;
    opacity: 0;
`

export const TotalBx = styled.div`
    margin-top: 50px;
    dl {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        border-bottom: 2px solid #000000;
        padding-bottom: 20px;
        
        dt {
            font-size: 34px;
            font-weight: 700;
        }

        dd {
            font-size: 34px;
            font-weight: 700;
        }
    }
`

export const SubTitleBx = styled.div`
    font-size: 24px;
    font-weight: 700;
`

export const ListBx = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    box-sizing: border-box;
    border-top: 1px solid #999999;
    margin-top: 10px;
    dl{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        dt{
            font-size: 17px;
            font-weight: 700;
            color: #000000;
            word-break: keep-all;
        }
        dd{
            font-size: 17px;
            font-weight: 700;
            color: #000000;
            word-break: keep-all;
        }
    }
    
    ${ media.mobile`
        padding: 15px 5px 0;
    ` };
`

export const MessageBx = styled.div`
    font-size: 12px;
    font-weight: 700;
    color: red;
    text-align: right;
    margin-top: 10px;

    ${ media.mobile`
        margin-top: 20px;
    ` };
`

export const SubListBx = styled.div`
    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 6px;

    dl {
        dt {
            color: #666666;
            font-size: 15px;
        }

        dd {
            font-size: 15px;
            color: #666666;
            width: 50px;
            text-align: right;
            flex-shrink: 0;
        }
    }
    
    ${ media.mobile`
        padding-left: 5px;
    ` }
`

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const GridBx = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    > div{
        min-height: 180px;
        border: 1px solid #999999;
        border-radius: 10px;
    }
    
    ${ media.tablet`
        grid-template-columns: repeat(2, 1fr);
    ` };
    
    ${ media.mobile`
        grid-template-columns: repeat(1, 1fr);
        > div{
            padding: 10px;
            min-height: auto;
            border-radius: 5px;
        }
    ` }
`

export const InfoBx = styled.div`
    dt{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    
    dd{
        font-size: 16px;
        margin-bottom: 10px;
        white-space: pre;
    }
`

export const ListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 70px;
    
    ${ media.mobile`
        grid-template-columns: repeat(1, 1fr);
    ` };
`

export const MessageListBx = styled.div`
    ul{
        display: flex;
        flex-direction: column;
        li{
            position: relative;
            padding-left: 10px;
            font-size: 14px;
            color: #666666;
            font-weight: 700;
            line-height: 18px;
            word-break: keep-all;
            &:before{
                content: '-';
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
`

export const BtnContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`