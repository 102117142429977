import * as S from "./styles";
import TableComponent from "../table-component";
import {
    TechnicalData,
    TechnicalContentData,
    TechnicalPackageData,
    TechnicalPriceData, TechnicalPriceInfoData,
} from "./data";
import TitleBx from "../common/title-bx";
import DiverInfo from "../recreation/openwater/diver-info";
import AdvencedPackage from "../common/advanced-package";
import {useCallback} from "react";
import {useToastContext} from "../popup-table-component/ToastProvider";

const Technical = () => {
    const { showToast } = useToastContext();

    const onClick = useCallback(() => {
        showToast({
            info: TechnicalPriceInfoData
        });

    }, []);

    return(
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'TECHNICAL DIVING'}
                    description={'즐거운 바다로, 안전하고 편안하게 안내해 드리겠습니다.'}
                />

                <TableComponent info={TechnicalData}></TableComponent>
                <DiverInfo info={TechnicalContentData} onClick={onClick}/>
                <TableComponent info={TechnicalPriceData}></TableComponent>
                <AdvencedPackage info={TechnicalPackageData}/>
            </S.Inner>
        </S.Wrap>
    )
}

export default Technical;