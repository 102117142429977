import axios from "axios";

const api = process.env.NODE_ENV === "development" ? "http://localhost:3090" : "http://13.209.69.181";

export const GetCalculatorData = async (num: number) => {
    const {data} = await axios.get(api + '/api/item', {
        params: {
            num,
        }
    });

    return data;
}

export const GetCalculatorList = async () => {
    const {data} = await axios.get(api + '/api/list');
    return data;
}

export const GetProcess = async (packageId: number) => {
    const { data } = await axios.get(api + '/api/package', {
        params: {
            packageId: packageId
        }
    });

    return data;
}

export const GetRental = async () => {
    const { data } = await axios.get( api + "/api/rental" );
    return data;
}

export const GetMore = async () => {
    const { data } = await axios.get( api + "/api/more" );
    return data;
}

export const Order = async (params: any) => {
    const { data } = await axios.post(api + "/api/order", {
        data: params.orderData
    });

    return data;
}