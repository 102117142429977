import {useEffect, useState} from "react";
import * as S from "./styles";
import {GetCalculatorList} from "../../../api/calculator";
import {Link} from "react-router-dom";
import TitleBx from "../../../components/common/title-bx";
import moment from "moment";

const CalculatorListPage = () => {
    const [listData, setListData] = useState<any>(null);

    const getData = async () => {
        const data = await GetCalculatorList();
        setListData(data);
    }

    useEffect(() => {
        getData();
    }, []);

    if (!listData) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <dt>New</dt>
                    <dd>비용 문의 리스트를 확인해보세요.</dd>
                </S.TitleBx>
                <S.ListContaienr>
                    <S.ListBx>
                        <ul>
                            {
                                listData.map((item: any, idx: number) => (
                                    <li key={`notice-${idx}`}>
                                        <Link to={`/expense/complete/${item.orderId}`}>
                                            <S.Process>{item.type}</S.Process>
                                            <S.MessageBx>
                                                {item.message || '문의 드립니다.'}
                                            </S.MessageBx>

                                            <S.InfoBx>
                                                <dt>{item.username || '익명'}</dt>
                                                <dd>{moment(item.createData).format("YYYY.MM.DD")}</dd>
                                            </S.InfoBx>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </S.ListBx>
                </S.ListContaienr>
            </S.Inner>
        </S.Wrap>
    )
}

export default CalculatorListPage;