import styled from "styled-components";
import media from "../../lib/media";
import {motion} from "framer-motion";

export const Wrap = styled(motion.div)`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
`

export const Inner = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
    
    ${ media.tablet`
        padding: 0 20px;
    ` }
`

export const TableBx = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 60px;

    ${ media.tablet`
    
    ` };
    ${ media.mobile`
        flex-direction: column;
        gap: 50px;
    ` };
`

export const TableInner = styled(motion.div)`
    width: 100%;

    h3 {
        text-align: center;
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 30px;
    }

    table {
        width: 100%;
        border-top: 4px solid #666666;

        tr {
            &:nth-child(2n+1) {
                background-color: rgba(0, 0, 0, 0.02);
            }

            th, td {
                border: 1px solid #999999;
                box-sizing: border-box;
                word-break: keep-all;
            }

            th {
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                background-color: #eeeeee;
                padding: 15px 5px;
                vertical-align: middle;
            }

            td {
                padding: 10px;

                &:first-child {
                    width: 300px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }

    ${media.tablet`
        table{
            tr{
                td{
                    &:first-child{
                        width: 220px;
                    }
                }
            }
        }
    `}


    ${media.mobile`
        table{
            tr{
                td{
                    &:first-child{
                        width: 140px;
                    }
                }
            }
        }
    `}
`

export const ContentBx = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

export const DescriptionBx = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    white-space: pre-wrap;
    ${ media.mobile`
        font-size: 14px;
    ` }
`

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    h4{
        font-size: 16px;
        font-weight: 700;
    }
    
    ul{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        li{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: #FFF2F3;
            width: 85px;
            height: 70px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 15px;
            color: #C6151B;
            
            span{
                margin-top: 2px;
                display: block;
                font-size: 13px;
            }
        }
    }

    ${ media.mobile`
        h4{
            font-size: 14px;
        }
        ul{
            display: grid;
            grid-template-columns: 1fr 1fr;
            li{
                width: 100%;
                font-size: 14px;
            }
        }
    ` }
`

export const ContentDescription = styled.ul`
    li{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    
    ${ media.mobile`
        li{
            font-size: 12px;
        }
    ` }
`

export const MessageBx = styled.div`
    border: 1px solid #999999;
    border-top: none;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const MessageList = styled(motion.dl)`
    dt{
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 5px;
    }
    
    dd{
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #C6151B;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        text-decoration: underline;
        white-space: pre-wrap;
    }
    
    ${ media.mobile`
        dt{
            font-size: 14px;
        }
        dd{
            font-size: 13px;
        }
    ` }
`