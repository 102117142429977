import PriceContent from "../../../components/price-content";

const PricePage = () => {
    return(
        <div>
            <PriceContent></PriceContent>
        </div>
    )
}

export default PricePage;