import styled from "styled-components";
import Visual from "../../main/visual";
import DiveAbout from "./dive-about";
import TeamMaxVideo from "./team-max-video";

const Wrap = styled.div`
    
`
const AboutContent = () => {
    return(
        <Wrap>
            <Visual
                title='아름다운 열대바다를 품고 있는 팀맥스 다이브 탑팀'
                description='편안한 휴식, 즐거운 다이빙의 세계로 안내해 드리겠습니다.'
                imgSrc={"/image/img_about_visual.png"}
            />
            <DiveAbout />
            <TeamMaxVideo />
        </Wrap>
    )
}

export default AboutContent;