import styled from "styled-components";
import {CprData, CprDiverContentData} from "./data";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import DiverInfo from "../openwater/diver-info";

const Wrap = styled.div`
`

const Cpr = () => {
    return(
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={CprData}/>
            <DiverInfo info={CprDiverContentData}/>
        </Wrap>
    )
}

export default Cpr;