import {DiveAboutData} from "./data";
import DiveAboutItem from "./dive-about-item";
import * as S from "./styles";

const DiveAbout = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <S.Title>팀맥스 다이브 탑팁 다이빙 리조트는 ...</S.Title>
                <S.ItemBx>
                    {
                        DiveAboutData.map((item, idx) => (
                            <DiveAboutItem
                                key={`dive-about-${idx}`}
                                item={item}
                            />
                        ))
                    }
                </S.ItemBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default DiveAbout;