import {DiverLessonAddContentType, DiverLessonType} from "../../../types/recreation";

export const CprData: DiverLessonType = {
    title: '이 과정에서는 ...',
    description: '응급환자를 처치하는 일차처치(First AID) / 이차처치(CPR)을 배우는 프로그램입니다.\n이 프로그램은 다이어뿐만 아니라, 일반 대중들도 교육 가능하며, 타이브 마스터의 사전조건으로써 리더쉽 과정으로 가기전에 반드시 거쳐야 할 코스 입니다.',
    infoList: [
        {
            type: 'TABLE',
            title: '과정 참가자격 및 준비물',
            infoType: [
                {
                    title: '참가자격',
                    description: '15세(주니어 10~14세)',
                },
                {
                    title: '소요시간',
                    description: '1일',
                },
                {
                    title: '준비물',
                    description: '증명사진 1부',
                },
                {
                    title: '비용',
                    description: '$300',
                }],
        },
        {
            type: 'ADD',
            title: '교육 절차',
            infoAdd:
                {
                    items: [
                        {
                            title: '심폐소생술과 응급처치',
                            type: 'DEFAULT',
                        },
                        {
                            title: '어린이를 위한 처치',
                            type: 'ADD',
                        }]
                }
        }],
}

export const CprDiverContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '조식/중식',
        },
        {
            title: '교재',
        },
    ],
    referenceList: [
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
                link: '/',
            }
        }
    ]
}