import * as S from "./styles";
import TitleBx from "../../../common/title-bx";
const TeamMaxVideo = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={"TEAM MAX STORY"}
                    description={""}
                />
                <S.VideoBx>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/y2YnvISdqf4?si=xiOJa0GucUvgDwwp"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </S.VideoBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default TeamMaxVideo;