import {DiverLessonAddContentType, DiverLessonPackageType, DiverLessonType} from "../../../types/recreation";

export const RescueData: DiverLessonType = {
    title: '이 과정에서는 ...',
    description: '다이빙 중에 일어나는 사고에 대한 자신 스스로의 대처방법과 남을 돕는 법과 사고를 통제하고 관리하는 방법에 대해서 배웁니다.\n또한 다이브 마스터의 사전 조건으로써 다이버 리더쉽 과정으로 가기전에 반드시 거쳐야 할 코스 입니다.',
    infoList: [
        {
            type: 'TABLE',
            title: '과정 참가자격 및 준비물',
            infoType: [
                {
                    title: '참가자격',
                    description: '15세 (주니어 10 ~ 14세)',
                },
                {
                    title: '소요시간',
                    description: '3일',
                },
                {
                    title: '준비물',
                    description: '수영복, 개인세면도구, 증명사진 1부',
                },
                {
                    title: '비용',
                    description: '$500',
                }],
        },
        {
            type: 'ADD',
            title: '교육 절차',
            infoAdd:
                {
                    items: [
                        {
                            title: '12가지 워터 스킬',
                            type: 'DEFAULT',
                        }]
                }
        }]
}

export const RescueDiverContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '숙박 3일',
            description: '(2인 1실 사용조건)'
        },
        {
            title: '공항픽업',
        },
        {
            title: '조식/중식',
        },
        {
            title: '교재',
        },
        {
            title: '장비렌탈',
        },
    ],
    referenceList: [
        {
            title: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.',
        },
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
                link: '/',
            }
        }
    ]
}

export const RescuedPackageData: DiverLessonPackageType[] = [
    {
        title: 'NAUI 레스큐 + 응급처치 패키지',
        description: '레스큐 + 응급처치 동시에 등록 하시면 총 3일 과정 $700에 할인하여 진행 해드립니다!',
        price: '$700',
        list: [
            {
                title: '포함사항',
                description: '숙박 3일(2인 1실 사용조건), 공항픽업, 조식/중식, 교재, 장비렌탈',
                subDescription: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.'
            },
            {
                title: '미포함 사항',
                description: '아일랜드 펀다이빙시 해양공원 입장료 & 씨푸드 바베큐 별도',
                linkInfo: {
                    title: '입장료 안내',
                    link: '/',
                }
            },
        ]
    },
]