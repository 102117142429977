import styled from "styled-components";
import media from "../../lib/media";
import {motion} from "framer-motion";

const Wrap = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto 20px;
    box-sizing: border-box;

    dl {
        display: flex;
        flex-direction: column;
        gap: 2px;

        dt {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #666666;
            word-break: keep-all;
        }

        dd {
            width: 100%;
            display: flex;
            align-items: center;
            word-break: keep-all;

            span {
                display: block;
                font-family: Poppins sans-serif;
                font-weight: 700;
                font-size: 48px;
                line-height: 72px;
                color: #0F0F10;
                flex-shrink: 0;
                padding-right: 30px;
            }

            i {
                content: '';
                display: block;
                width: 100%;
                height: 10px;
                background-color: #C6151B;
            }
        }
    }

    ${media.tablet`
        padding: 0 20px;
        dl{
            dd{
                flex-direction: column;
                align-items: flex-start;
            }
        }
    `};

    ${media.mobile`
        margin: 0 auto 30px;
        dl{
            display: inline-flex;
            dt{
                font-size: 14px;
                line-height: 19px;
            }
            dd{
                span{
                    font-size: 30px;
                    line-height: 45px;
                    padding-right: 0px;
                }
                
                i {
                    height: 4px;
                }
            }
        }
    `};
`

interface Props {
    title: string;
    description: string;
}

const TitleBx = ({title, description}: Props) => {
    return (
        <Wrap>
            <dl>
                <motion.dt
                    initial={{opacity: 0, x: 20}}
                    animate={{opacity: 1, x: 0}}
                    transition={{
                        duration: 0.35,
                        ease: 'easeOut'
                    }}
                >{description}</motion.dt>
                <dd>
                    <motion.span
                        initial={{opacity: 0, x: 20}}
                        animate={{opacity: 1, x: 0}}
                        transition={{
                            duration: 0.35,
                            ease: 'easeOut',
                        }}
                    >{title}</motion.span>
                    <motion.i
                        initial={{width: '0%'}}
                        animate={{width: '100%'}}
                        transition={{
                            duration: 1,
                            ease: 'easeOut',
                            delay: 0.15,
                        }}
                    ></motion.i>
                </dd>
            </dl>
        </Wrap>
    )
}

export default TitleBx