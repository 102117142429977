import styled from "styled-components";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import DiverInfo from "./diver-info";
import AdvencedPackage from "../../common/advanced-package";
import {OpenWaterLessonData, OpenWaterDiverContentData, OpenWaterPackageData} from "./data";

const Wrap = styled.div`

`

const OpenWater = () => {
    return (
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={OpenWaterLessonData}/>
            <DiverInfo info={OpenWaterDiverContentData}/>
            <AdvencedPackage info={OpenWaterPackageData}/>
        </Wrap>
    )
}

export default OpenWater;