import {DiverInfoType} from "../../../../types/recreation";
import * as S from "./styles";


const DiverInfoItem = ({title, description}: DiverInfoType) => {
    return (
        <S.ItemWrap>
            <dl>
                <dt>{title}</dt>
                {
                    description &&
                    <dd>
                        <span>{description}</span>
                    </dd>
                }
            </dl>
        </S.ItemWrap>
    )
}

export default DiverInfoItem;