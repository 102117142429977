import styled from "styled-components";
import {media} from "../../../lib/media";

export const Wrap = styled.div`
    position: sticky;
    z-index: 999;
    width: 100%;
    left: 0;
    top: -60px;
    background-color: #ffffff;

    ${media.tablet`
        top: -72px;
    `};

    ${media.tablet`
        top: 0px;    
    `};
`

export const AdminBx = styled.div`
    background-color: #0F0F10;
    box-sizing: border-box;
    padding: 20px 200px 20px 172px;

    ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 40px;

        li {
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #ffffff;
                }
            }
        }
    }

    ${media.tablet`
        display: none;
    `};
`

export const Inner = styled.div`
    margin: 0 auto;
    max-width: 1920px;
    width: 100%;
`

export const GnbBx = styled.div`
    box-sizing: border-box;
    padding: 0 200px 0 172px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    ${media.tablet2`
        padding: 16px 0px 0px;
        flex-direction: column;
    `}

    ${media.mobile`
        padding: 16px 0;
    `}
`

export const LogoBx = styled.div`
    position: relative;
    z-index: 10;

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        &:hover {
            img {
                transition: transform 10s linear;
                transform: rotate(360deg);
            }
        }

        h1 {
            font-family: Poppins sans-serif;
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: #0F0F10;
        }
    }

    button {
        display: none;
        position: absolute;
        top: 8px;
        right: 20px;
        font-size: 0;
        line-height: 0;
    }

    ${media.mobile`
        width: 100%;
        display: flex;
        justify-content: center;
        a{
            img{ width: 40px; height: 40px; }
            h1{
                display: none;
            }
        }
        button{
            display: block;
        }
    `};
`

export const MenuBx = styled.div`
    background-color: #ffffff;

    ${media.mobile`
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        padding-top: 72px;
        transform: translateX( 100% );
    `};
`

export const Depth1BxContainer = styled.div<{ $isIn: boolean }>`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;

    ${media.mobile`
        align-items: flex-start;
        height: calc(100dvh - 72px );
        box-sizing: border-box;
        overflow-y: auto;
        overscroll-behavior: contain;
        -ms-overflow-style: none;
        scrollbar-width: none;
        transition: transform 0.35s;
        transform: translateX(${(p: any) => p.$isIn ? '-100%' : '0%'});
    `};
`

export const Depth1Bx = styled.ul`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;

    ${media.mobile`
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 20px;
        padding: 0 20px;
        min-height: calc( 100% + 1px );
        height: auto;
    `};
`

export const Depth1Menu = styled.li<{ $isOn: boolean }>`
    &:hover {
        > a, > button {
            cursor: pointer;
            color: #C6151B;

            span {
                transform: rotate(180deg);
                img {
                    &:nth-child(1) {
                        opacity: 0;
                    }

                    &:nth-child(2) {
                        opacity: 1;
                    }
                }
            }
        }
    }

    > a, > button {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        padding: 0 16px;
        transition: color 0.25s;
        height: 96px;
        color: ${p => p.$isOn ? '#C6151B' : '#0F0F10'};

        span {
            position: relative;
            display: inline-block;
            width: 12px;
            height: 12px;

            img {
                position: absolute;
                left: 0;
                top: 0;
                transition: opacity 0.25s;

                ${p => p.$isOn ? `
                    &:nth-child(1){ opacity: 0; }
                    &:nth-child(2){ opacity: 1; }
                ` : `
                    &:nth-child(1){ opacity: 1; }
                    &:nth-child(2){ opacity: 0; }
                `};
            }
        }

        ${media.tablet`
            height: 80px;
        `};

        ${media.mobile`
            height: auto;
            padding: 0;
            margin-bottom: 12px;
        `};
    }

    ${media.mobile`
        width: 100%;
        &:last-child{
            ul{
                padding-bottom: 20px;
            }
        }
    `};
`

export const SubMenuBx = styled.div<{ $isShow?: boolean }>`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate3d(-50%, 100%, 0);
    width: 100%;
    background-color: #ffffff;
    display: ${p => p.$isShow ? 'block' : 'none'};

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${media.mobile`
        position: relative;
        left: 0;
        bottom: 0;
        transform: translate3d( 0, 0, 0 );
        overflow: hidden;
        width: 100%;
        ul{
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    `};
`

export const SubMenuItem = styled.li<{ $isOn: boolean }>`
    flex-shrink: 0;

    a, button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: ${p => p.$isOn ? '#C6151B' : '#0F0F10'};
        height: 88px;
        padding: 0 16px;
        transition: color 0.25s;

        &:hover {
            color: #C6151B;
        }
    }

    ${media.mobile`
        width: 100%;
        a, button{
            height: auto;
            padding: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
        }
    `}
`

export const KakaoBx = styled.div`
    ${media.mobile`
        display: none;
    `};
`
