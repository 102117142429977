import styled from "styled-components";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import {AdvancedData, AdvancedDiverContentData, AdvancedPackageData} from "./data";
import DiverInfo from "../openwater/diver-info";
import AdvencedPackage from "../../common/advanced-package";

const Wrap = styled.div`

`

const Advanced = () => {
    return (
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={AdvancedData}/>
            <DiverInfo info={AdvancedDiverContentData}/>
            <AdvencedPackage info={AdvancedPackageData}/>
        </Wrap>
    )
}

export default Advanced;