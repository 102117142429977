import {useParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import * as S from "./styles";
import BtnDefault from "../common/btn-default";
import {CalculatorCompleteDataType, CalculatorDefaultType} from "../../types/calculator";
import {getComma} from "../../lib/comma";
import TitleBx from "../common/title-bx";
import {GetCalculatorData} from "../../api/calculator";

function getAddPrice(arr: any) {
    return arr.reduce((a: number, b: any) => !isNaN(b.price) ? a + +b.price : a, 0);
}

const CompleteContent = () => {
    const {num} = useParams();
    const [data, setData] = useState<CalculatorCompleteDataType>();

    const textAreaRef = useRef(null);
    const onClickClipboard = useCallback(() => {
        if (window) {
            if (textAreaRef.current) {
                const textArea = textAreaRef.current as HTMLTextAreaElement;

                if (navigator.clipboard) {
                    window.navigator.clipboard.writeText(textArea.value);
                    console.log('clipboard');
                } else {
                    textArea.select();
                    document.execCommand('copy');
                    console.log('copy');
                }
            }

            alert('공유 링크가 복사 되었습니다.');
        }
    }, []);

    const getData = async () => {
        const data = await GetCalculatorData(Number(num));
        setData(data);
    }

    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        console.log(data);
    }, [data]);

    if (!data) return null;

    return (
        <S.Wrap>
            <S.TitleBx>
                <TitleBx
                    title={data.process?.type === 'FUNDIVING' ? '펀 다이빙' : '교육 다이빙'}
                    description={'계획 결과을 알려드립니다.'}
                />

                <S.ShareBx>
                    <BtnDefault title={'링크 공유하기'} onClick={onClickClipboard}/>
                    <div>
                        <S.TextAreaBx ref={textAreaRef} value={window.location.href} readOnly/>
                    </div>
                </S.ShareBx>
            </S.TitleBx>

            <S.Inner>
                <S.ContentBx>

                    <S.InfoBx>
                        <dt>작성자 : { data.info?.userName }</dt>
                        <dd>
                            { data.info?.message}
                        </dd>
                    </S.InfoBx>

                    <S.ListContainer>
                        <div>
                            <S.SubTitleBx>과정</S.SubTitleBx>

                            {
                                data.funDivingList && <S.ListBx>
                                    <>
                                        <dl>
                                            <dt>{data.funDivingList.process.name}</dt>
                                            <dd>
                                                ${data.funDivingList.process.price}
                                                <span>
                                                +{
                                                    getComma(getAddPrice(data.funDivingList.processList))
                                                }
                                                </span>
                                            </dd>
                                        </dl>
                                        <S.SubListBx>
                                            {
                                                data.funDivingList.processList.map((v: CalculatorDefaultType, idx: number) => (
                                                    <dl key={`fun-diving-${idx}`}>
                                                        <dt>- {v.name}</dt>
                                                        <dd>${getComma(v.price)}</dd>
                                                    </dl>
                                                ))
                                            }
                                        </S.SubListBx>
                                    </>
                                </S.ListBx>
                            }

                            {
                                data.technicalList && <S.ListBx>
                                    <>
                                        <dl>
                                            <dt>{data.technicalList.process.name}</dt>
                                            <dd>
                                                <span>
                                                ${
                                                    getComma(getAddPrice(data.technicalList.processList))
                                                }
                                                </span>
                                            </dd>
                                        </dl>
                                        <S.SubListBx>
                                            {
                                                data.technicalList.processList.map((v: CalculatorDefaultType, idx: number) => (
                                                    <dl key={`technical-${idx}`}>
                                                        <dt>- {v.name}</dt>
                                                        <dd>${getComma(v.price)}</dd>
                                                    </dl>
                                                ))
                                            }
                                        </S.SubListBx>
                                    </>
                                </S.ListBx>
                            }
                        </div>

                        <div>
                            <S.SubTitleBx>날짜</S.SubTitleBx>
                            <S.ListBx>
                                {
                                    data.schedule && <>
                                        {
                                            data.schedule.startDay && <dl>
                                                <dt>시작 날짜</dt>
                                                <dd>{data.schedule.startDay}</dd>
                                            </dl>
                                        }
                                        {
                                            data.schedule.endDay && <dl>
                                                <dt>마지막 날짜</dt>
                                                <dd>{data.schedule.endDay}</dd>
                                            </dl>
                                        }
                                        {
                                            data.schedule.day && <dl>
                                                <dt>총 일자</dt>
                                                <dd>{data.schedule.day}일</dd>
                                            </dl>
                                        }
                                    </>
                                }
                            </S.ListBx>
                        </div>

                        <div>
                            <S.SubTitleBx>참가인원</S.SubTitleBx>
                            <S.ListBx>
                                {
                                    data.gender && <>
                                        <dl>
                                            <dt>남자</dt>
                                            <dd>{data.gender.man.member}명</dd>
                                        </dl>
                                        <dl>
                                            <dt>여자</dt>
                                            <dd>{data.gender.woman.member}명</dd>
                                        </dl>
                                    </>
                                }
                            </S.ListBx>
                        </div>

                        {
                            data.more && data.more.length > 1 && <div>
                                <S.SubTitleBx>그외</S.SubTitleBx>
                                <S.ListBx>
                                    {
                                        <>
                                            <dl>
                                                <dt>가고 싶은곳</dt>
                                                <dd>
                                                    ${getComma(getAddPrice(data.more))}
                                                </dd>
                                            </dl>
                                            <S.SubListBx>
                                                {
                                                    data.more.map((item: any, idx: number) => (
                                                        <dl key={`more-list-${idx}`}>
                                                            <dt>- {item.name}</dt>
                                                            <dd>${item.price}</dd>
                                                        </dl>
                                                    ))
                                                }
                                            </S.SubListBx>
                                        </>
                                    }
                                </S.ListBx>
                                {
                                    data.more && <S.MessageBx>* 당일 기상상황 고려하여 진행이 불가능할 수 있습니다.</S.MessageBx>
                                }
                            </div>

                        }
                    </S.ListContainer>

                    <div>
                        <S.SubTitleBx>렌탈</S.SubTitleBx>
                        {
                            data.rental && <S.GridContainer>
                                <S.GridBx>
                                    {
                                        data.rental.man.map((list: any, idx: number) => (
                                            <S.ListBx key={`rental-man-${idx}`}>
                                                <dl>
                                                    <dt>남자({idx + 1})</dt>
                                                    <dd>
                                                        $
                                                        <span>{getComma(getAddPrice(list))}</span>
                                                    </dd>
                                                </dl>
                                                <S.SubListBx>
                                                    {
                                                        list.length > 0 ? (
                                                            list.map((item: any, sIdx: number) => (
                                                                <dl key={`rental-man-list-${idx}-${sIdx}`}>
                                                                    <dt>- {item.name}</dt>
                                                                    <dd>${item.price}</dd>
                                                                </dl>
                                                            ))
                                                        ) : (
                                                            <div style={{'fontWeight': '700'}}>(X) 선택 사항 없음</div>
                                                        )
                                                    }
                                                </S.SubListBx>
                                            </S.ListBx>
                                        ))
                                    }
                                </S.GridBx>

                                <S.GridBx>
                                    {
                                        data.rental.woman.map((list: any, idx: number) => (
                                            <S.ListBx key={`rental-woman-${idx}`}>
                                                <dl>
                                                    <dt>여자({idx + 1})</dt>
                                                    <dd>
                                                        $
                                                        <span>{getComma(getAddPrice(list))}</span>
                                                    </dd>
                                                </dl>
                                                <S.SubListBx>
                                                    {
                                                        list.length > 0 ? (
                                                            list.map((item: any, sIdx: number) => (
                                                                <dl key={`rental-woman-list-${idx}-${sIdx}`}>
                                                                    <dt>- {item.name}</dt>
                                                                    <dd>${item.price}</dd>
                                                                </dl>
                                                            ))
                                                        ) : (
                                                            <div style={{'fontWeight': '700'}}>(X) 선택 사항 없음</div>
                                                        )
                                                    }
                                                </S.SubListBx>
                                            </S.ListBx>
                                        ))
                                    }
                                </S.GridBx>
                            </S.GridContainer>
                        }
                    </div>

                    <S.MessageListBx>
                        {
                            data.process?.type === 'TECHNICAL' ? (
                                <ul>
                                    <li>교육 기간 내에 모든 스킬을 숙달할 수 없는 경우도 있습니다.</li>
                                    <li>그럴 경우에 메이크업 교육을 진행 할 수 있습니다.</li>
                                    <li>메이크업의 경우 본인이 요청한 경우에는 비용이 부과됩니다.</li>
                                    <li>하지만, 비정기적으로 열리는 메이크업 클래스의 경우 추가 비용 없이 무료로 참석 가능합니다.</li>
                                    <li>비용 계산 기능은 예상치이며 실제 서비스 진행 과정에서 추가되거나 빠지는 서비스가 있을 수 있음으로 100% 동일한 금액으로 진행 되지
                                        않습니다.
                                    </li>
                                    <li>자세한 내용은 문의하기를 눌러주세요.</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>비용 계산 기능은 예상치이며 실제 서비스 진행 과정에서 추가되거나 빠지는 서비스가 있을 수 있음으로 100% 동일한 금액으로 진행 되지
                                        않습니다.
                                    </li>
                                    <li>자세한 내용은 문의하기를 눌러주세요.</li>
                                    <li>강사를 등록하시면 다양한 혜택을 확인 하실 수 있습니다.</li>
                                </ul>
                            )
                        }
                    </S.MessageListBx>

                    <S.TotalBx>
                        <dl>
                            <dt>총 금액</dt>
                            <dd>${data.total && getComma(data.total.totalPrice)}</dd>
                        </dl>
                    </S.TotalBx>


                    <S.BtnContainer>
                        <BtnDefault link={'/expense/calculator'} title={'다시 계획 짜기'}></BtnDefault>
                        {/*<BtnDefault link={'/expense/list'} title={'리스트'}></BtnDefault>*/}
                    </S.BtnContainer>
                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default CompleteContent;
