import {PackageItemsType} from "../../../types/main";


export const ItemData: PackageItemsType[] = [
    {
        type: 'RED',
        link: '',
        src: '/image/img_package_item_00.png',
        price: 450,
        title: '오픈워터 패키지 (3일)',
        description: '숙박 (2인 1실) | 공항 픽업 | 조/중식\n교재 | 교육 | 라이센스 | 장비렌탈'
    },
    {
        type: 'RED',
        link: '',
        src: '/image/img_package_item_01.png',
        price: 450,
        title: '오픈워터 패키지 (3일)',
        description: '숙박 (2인 1실) | 공항 픽업 | 조/중식\n교재 | 교육 | 라이센스 | 장비렌탈'
    },
    {
        type: 'RED',
        link: '',
        src: '/image/img_package_item_02.png',
        price: 450,
        title: '오픈워터 패키지 (3일)',
        description: '숙박 (2인 1실) | 공항 픽업 | 조/중식\n교재 | 교육 | 라이센스 | 장비렌탈'
    },
    {
        type: 'BORA',
        link: '',
        src: '/image/img_package_item_03.png',
        price: 450,
        title: '오픈워터 패키지 (3일)',
        description: '숙박 (2인 1실) | 공항 픽업 | 조/중식\n교재 | 교육 | 라이센스 | 장비렌탈'
    },
    {
        type: 'BORA',
        link: '',
        src: '/image/img_package_item_04.png',
        price: 450,
        title: '오픈워터 패키지 (3일)',
        description: '숙박 (2인 1실) | 공항 픽업 | 조/중식\n교재 | 교육 | 라이센스 | 장비렌탈'
    },
    {
        type: 'BORA',
        link: '',
        src: '/image/img_package_item_05.png',
        price: 450,
        title: '오픈워터 패키지 (3일)',
        description: '숙박 (2인 1실) | 공항 픽업 | 조/중식\n교재 | 교육 | 라이센스 | 장비렌탈'
    },
    {
        type: 'BLUE',
        link: '',
        src: '/image/img_package_item_06.png',
        price: 450,
        title: '오픈워터 패키지 (3일)',
        description: '숙박 (2인 1실) | 공항 픽업 | 조/중식\n교재 | 교육 | 라이센스 | 장비렌탈'
    },
]