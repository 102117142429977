import {InfoListTableType} from "../../types/common";
import {DiverLessonAddContentType, DiverLessonPackageType} from "../../types/recreation";

export const TechnicalData: InfoListTableType[] = [
    {
        title: '테크니컬 다이빙 교육 비용(NAUI)',
        subTitle: ['과정명(NAUI)', '소요일정'],
        list: [
            {
                contents: [
                    {description: '인트로 투 텍',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: '텍 사이드 마운트',},
                    {description: '최소 4일',},
                ]
            },
            {
                contents: [
                    {description: '어드밴스드 텍 사이드 마운트',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: '감압 테크닉',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: '블랜더',},
                    {description: '최소 2일',},
                ]
            },
            {
                contents: [
                    {description: 'DPV',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: '트라이믹스 1',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: '트라이믹스 2',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: '캐번',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: '케이브 1',},
                    {description: '최소 7일',},
                ]
            },
            {
                contents: [
                    {description: '케이브 2',},
                    {description: '최소 8일',},
                ]
            },
            {
                contents: [
                    {description: '케이브 DPV',},
                    {description: '최소 4일',},
                ]
            },
        ],

        messageList: [
            {
                title: '* 교육비',
                message: '1일 1:1 - $300\n1일 2:1 - $250',
            },
            {
                title: '* 트라이믹스 1,2 & 케이브',
                message: '1:1 - $300\n2:1 이상 - $300(헬륨비용별도) 최소 교육일 이후 메이크업 비용 하루 $200(숙박제외)',
            }
        ]
    },
    {
        title: '테크니컬 다이빙 교육 비용(TDI)',
        subTitle: ['과정명(TDI)', '소요일정'],
        list: [
            {
                contents: [
                    {description: '인트로 투 텍',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: '어드밴스드 나이트록스',},
                    {description: '최소 2일',},
                ]
            },
            {
                contents: [
                    {description: '감압절차',},
                    {description: '최소 4일',},
                ]
            },
            {
                contents: [
                    {description: 'ERD',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: '블랜더',},
                    {description: '최소 2일',},
                ]
            },
            {
                contents: [
                    {description: 'DPV',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: '트라이믹스 1',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: '트라이믹스 2',},
                    {description: '최소 5일',},
                ]
            },
        ],

        messageList: [
            {
                title: '* 교육비',
                message: '1일 1:1 - $300\n1일 2:1 - $250',
            },
            {
                title: '* 트라이믹스 1,2 & 케이브',
                message: '1:1 - $300\n2:1 이상 - $300(헬륨비용별도) 최소 교육일 이후 메이크업 비용 하루 $200(숙박제외)',
            }
        ]
    },
]

export const TechnicalContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '숙박',
            description: '(2인 1실 사용조건)'
        },
        {
            title: '공항픽업',
        },
        {
            title: '조식/중식',
        },
        {
            title: '교재',
        },
        {
            title: '장비렌탈',
        },
    ],
    referenceList: [
        {
            title: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.',
        },
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
            }
        }
    ]
}

export const TechnicalPriceData: InfoListTableType[] = [
    {
        title: '테크니컬 펀다이빙 비용',
        subTitle: ['종류', '금액'],
        list: [
            {
                contents: [
                    {description: '감압 다이빙 1일',},
                    { price: '$200' },
                ]
            },
            {
                contents: [
                    {description: '트라이믹스 다이빙 1일',},
                    { price: '$300' },
                ]
            },
        ]
    }
]

export const TechnicalPackageData: DiverLessonPackageType[] = [
    {
        title: '테크니컬 펀다이빙 패키지(감압다이빙 이상)',
        price: '1일 $170',
        list: [
            {
                title: '포함사항',
                description: '숙박(2인 1실 사용조건), 공항픽업, 조식/중식, 더블실린더, 사이드실린더, 50% 산소',
                subDescription: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.'
            },
            {
                title: '미포함사항',
                description: '아일랜드 펀 다이빙시 해양공원 입장료 & 씨푸드 바베큐 별도',
            }
        ]
    }
]

export const TechnicalPriceInfoData: InfoListTableType[] = [
    {
        title: '입장료 및 씨푸드 바베큐 요금',
        subTitle: [],
        list: [
            {
                contents: [
                    {description: '올랑고', addPrice: '$2'},
                    {description: '씨푸드 레스토랑 이용시 추가', addPrice: '$12'},
                ]
            },
            {
                contents: [
                    {description: '힐룽뚱안', addPrice: '$6'},
                    {description: '-',},
                ]
            },
            {
                contents: [
                    {description: '날루수안', addPrice: '$6'},
                    {description: '-',},
                ]
            },
            {
                contents: [
                    {description: '샹그릴라', addPrice: '$6'},
                    {description: '-',},
                ]
            },
        ]
    }
]

