import styled from "styled-components";
import media from "../../lib/media";

export const Wrap = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto 60px;
`

export const Inner = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
`

export const ImgBx = styled.div`
    width: 100%;
    height: 600px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    ${ media.mobile`
        height: 350px;
    ` };
`

export const SwiperBx = styled.div`
    .swiper-button-next, .swiper-button-prev{
        background-color: rgba( 0, 0, 0, 0.1 );
        padding: 5px;
        color: white;
        
        &:hover{
            &::after{
                font-size: 25px;
            }
        }
        &::after{
            transition: font-size 0.125s ease-out;
            font-size: 30px;
        }
    }
    
    .swiper-pagination-bullet{
        background-color: white;
        opacity: 0.35;
    }
    
    .swiper-pagination-bullet-active{
        opacity: 1;
    }
`